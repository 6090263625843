import React, { useEffect, useState } from "react";
import NavBar from "../element/NavBar";
import Footer from "../element/NewFooter";
import { Link } from "react-router-dom";
import axios from "axios";
import BaseApi from "../api/BaseApi";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import Header from "../element/Header";

const Contact = () => {
  const [contactData, setContactData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    usertype: "",
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [t, i18n] = useTranslation("global");

  let primaryColor = Cookies.get("primaryColor");
  let secondaryColor = Cookies.get("secondaryColor");
  let captchaKey = Cookies.get("captchaKey");
  const recaptchaLanguage = Cookies.get("selectedLanguage");

  const [hoverColor, setHoverColor] = useState(false);

  const handleMouseEnter = () => {
    setHoverColor(true);
  };
  const handleCaptchaVerify = (value) => {
    setIsCaptchaVerified(value);
    if (isCaptchaVerified) {
      setErrors({
        captcha: "",
      });
    }
  };

  const handleMouseLeave = () => {
    setHoverColor(false);
  };
  const [recaptchaKey, setRecaptchaKey] = useState(captchaKey);
  const [recaptchaVisible, setRecaptchaVisible] = useState(true);

  const handleRecaptchaReset = () => {
    setRecaptchaVisible(false);
    setRecaptchaKey(generateRecaptchaKey());
    setTimeout(() => {
      setRecaptchaVisible(true);
    }, 0); // Using setTimeout to unmount and then mount the ReCAPTCHA
  };

  const generateRecaptchaKey = () => {
    return Math.random().toString(36).substring(2, 15);
  };
  const getData = async () => {
    try {
      const response = await axios.get(BaseApi + `/page/contact-us`);
      setContactData(response.data.response.contact_details);
    } catch (error) {
      console.log("Error getting contact information!");
    }
  };
  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);

  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      const newErrors = {};

      if (formData.usertype === "") {
        newErrors.usertype = "Please select your user type";
      }
      if (formData.name === "") {
        newErrors.name = t("messageForm.nameRequired");
      }
      if (formData.email === "") {
        newErrors.email = t("messageForm.emailRequired");
      } else if (!isValidEmail(formData.email)) {
        newErrors.email = "Invalid email format";
      }
      if (formData.subject === "") {
        newErrors.subject = t("messageForm.subjectRequired");
      }
      if (formData.message === "") {
        newErrors.message = t("messageForm.messageRequired");
      }
      if (!isCaptchaVerified) {
        newErrors.captcha = t("messageForm.captchaRequired");
      }

      setErrors(newErrors);

      // Function to validate email format
      function isValidEmail(email) {
        // Use a regular expression to validate email format
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(email);
      }

      if (Object.keys(newErrors).length === 0) {
        if (isCaptchaVerified) {
          setLoading(true);
          const response = await axios.post(
            BaseApi + "/page/contact-us",
            formData
          );
          setLoading(false);
          // Reset the reCAPTCHA
          handleRecaptchaReset();
          if (response.data.status === 200) {
            Swal.fire({
              title: t("messageForm.messageSuccessTitle"),
              icon: "success",
              confirmButtonText: t("messageForm.close"),
            });
            setFormData({
              ...formData,
              name: "",
              email: "",
              subject: "",
              message: "",
            });
          } else if (response.data.status === 500) {
            Swal.fire({
              title: response.data.message,
              icon: "error",
              confirmButtonText: t("messageForm.close"),
            });
          } else {
            Swal.fire({
              title: response.data.message,
              icon: "error",
              confirmButtonText: t("messageForm.close"),
            });
          }
        }
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: t("messageForm.messageFailedTitle"),
        text: t("messageForm.messageFailedTxt"),
        icon: "error",
        confirmButtonText: t("messageForm.close"),
      });
    }
    console.log(formData);
  };

  return (
    <>
      <div className="contactUs">
        {/* <NavBar /> */}
        <Header />
        {loading ? (
          <div className="loader-container"></div>
        ) : (
          <>
            {/* <div className="contactSection">
              <div className="container employerLogin EPRegistration">
                <div className="row  ">
                  <div className="col-md-12  ">
                    <div
                      className="  py-5 px-3 formCentere shadow-sm rounded"
                      style={{ background: "#F9F7FF" }}
                    >
                      <form className="border border-light">
                        <div className="mb-4">
                          <input
                            type="text"
                            className={`form-control ${
                              errors.name && "input-error"
                            }`}
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            name="name"
                            value={formData.name}
                            placeholder={t("faq.namePlaceholder")}
                            onChange={handleChange}
                          />
                          {errors.name && (
                            <div className="text-danger">{errors.name}</div>
                          )}
                        </div>
                        <div className="mb-4">
                          <input
                            type="email"
                            className={`form-control ${
                              errors.email && "input-error"
                            }`}
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            name="email"
                            value={formData.email}
                            placeholder={t("faq.emailPlaceholder")}
                            onChange={handleChange}
                          />
                          {errors.email && (
                            <div className="text-danger">{errors.email}</div>
                          )}
                        </div>
                        <div className="mb-4">
                          <select
                            className={`form-select ${
                              errors.subject && "input-error"
                            }`}
                            aria-label="Default select example"
                            value={formData.subject}
                            name="subject"
                            onChange={handleChange}
                          >
                            <option>{t("faq.selectPlaceholder")}</option>
                            <option value="1">
                              {t("faq.messageFormSelectOption1")}
                            </option>
                            <option value="2">
                              {t("faq.messageFormSelectOption2")}
                            </option>
                            <option value="3">
                              {t("faq.messageFormSelectOption3")}
                            </option>
                           
                          </select>
                          {errors.subject && (
                            <div className="text-danger">{errors.subject}</div>
                          )}
                        </div>
                        <div className="mb-4">
                          <textarea
                            className={`form-control ${
                              errors.message && "input-error"
                            }`}
                            id="exampleFormControlTextarea1"
                            rows="3"
                            name="message"
                            value={formData.message}
                            placeholder={t("faq.descPlaceholder")}
                            onChange={handleChange}
                          ></textarea>
                          {errors.message && (
                            <div className="text-danger">{errors.message}</div>
                          )}
                        </div>

                        <div className="reCaptchaLogin mb-4">
                          <ReCAPTCHA
                            sitekey={captchaKey}
                            
                            onChange={(value) => handleCaptchaVerify(value)}
                          />
                          {errors.captcha && (
                            <div className="text-danger CaptchaVerify">
                              {errors.captcha}
                            </div>
                          )}
                        </div>
                        <button
                          type="submit"
                          className="btn w-100"
                          onClick={handleClick}
                          style={{
                            backgroundColor: `${
                              secondaryColor &&
                              (hoverColor ? secondaryColor : primaryColor)
                            }`,
                            color: "white",
                          }}
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        >
                          SEND MESSAGE
                          {t("faq.sendMessageButton")}
                        </button>
                      </form>
                    </div>
                  </div>
                 
                </div>
              </div>
            </div> */}

            {/* <div className="ContactSection3 container mt-3">
              <div className="row">
                <form></form>

                
              </div>
            </div> */}
            <div className="contact-general" style={{ minHeight: "50vh" }}>
              <div className="container  Contactformgeneral">
                <div className="row">
                  <div className="col-md-8  ">
                    <h2>Contact Us</h2>
                    <p>
    Need assistance or looking for further information? <br />
    Please don't hesitate to contact us and we'll get back to you as soon as possible.
  </p>
                    <div
                      className="  py-5 px-3  "
                      // style={{ background: "#F9F7FF" }}
                    >
                      <form className="border-0t">
                        <div className="mb-4">
                          <div className="newcontact_form mb-4">
                            <label htmlFor="" className="mb-2">
                              I am a
                            </label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              value={formData.usertype}
                              name="usertype"
                              onChange={handleChange}
                            >
                              <option selected value="">
                                {t("faq.selectPlaceholder")}
                              </option>
                              <option value="jobseeker">Job Seeker</option>
                              <option value="employer">Employer</option>
                            </select>
                            {errors.usertype && (
                              <div className="text-danger">
                                {errors.usertype}
                              </div>
                            )}
                          </div>

                          <div className="newcontact_form mb-4">
                            <label htmlFor="" className="mb-2">
                              Subject{" "}
                            </label>
                            <select
                              className={`form-select ${
                                errors.subject && "input-error"
                              }`}
                              aria-label="Default select example"
                              value={formData.subject}
                              name="subject"
                              onChange={handleChange}
                            >
                              <option>{t("faq.selectPlaceholder")}</option>
                              <option value="information">Help</option>
                              <option value="information">Information</option>
                              <option value="advertising">Advertising</option>
                              <option value="feedback">Feedback</option>
                            </select>
                            {errors.subject && (
                              <div className="text-danger">
                                {errors.subject}
                              </div>
                            )}
                          </div>
                          <div className="newcontact_form mb-4">
                            <label htmlFor="" className="mb-2">
                              Name{" "}
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                errors.name && "input-error"
                              }`}
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              name="name"
                              value={formData.name}
                              placeholder={t("faq.namePlaceholder")}
                              onChange={handleChange}
                            />
                            {errors.name && (
                              <div className="text-danger">{errors.name}</div>
                            )}
                          </div>
                          <div className="newcontact_form mb-4">
                            <label htmlFor="" className="mb-2">
                              Email
                            </label>
                            <input
                              type="email"
                              className={`form-control ${
                                errors.email && "input-error"
                              }`}
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              name="email"
                              value={formData.email}
                              placeholder={t("faq.emailPlaceholder")}
                              onChange={handleChange}
                            />
                            {errors.email && (
                              <div className="text-danger">{errors.email}</div>
                            )}
                          </div>
                          <div className="newcontact_form mb-4">
                            <label htmlFor="" className="mb-2">
                              Message{" "}
                            </label>
                            <textarea
                              className={`form-control ${
                                errors.message && "input-error"
                              }`}
                              id="exampleFormControlTextarea1"
                              rows="3"
                              name="message"
                              value={formData.message}
                              placeholder={t("faq.descPlaceholder")}
                              onChange={handleChange}
                            ></textarea>
                            {errors.message && (
                              <div className="text-danger">
                                {errors.message}
                              </div>
                            )}
                          </div>

                          {/* <input
                            type="text"
                            className={`form-control ${
                              errors.name && "input-error"
                            }`}
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            name="name"
                            value={formData.name}
                            placeholder={t("faq.namePlaceholder")}
                            onChange={handleChange}
                          />
                          {errors.name && (
                            <div className="text-danger">{errors.name}</div>
                          )} */}
                        </div>
                        {/* <div className="mb-4">
                          <input
                            type="email"
                            className={`form-control ${
                              errors.email && "input-error"
                            }`}
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            name="email"
                            value={formData.email}
                            placeholder={t("faq.emailPlaceholder")}
                            onChange={handleChange}
                          />
                          {errors.email && (
                            <div className="text-danger">{errors.email}</div>
                          )}
                        </div> */}
                        {/* <div className="mb-4">
                          <select
                            className={`form-select ${
                              errors.subject && "input-error"
                            }`}
                            aria-label="Default select example"
                            value={formData.subject}
                            name="subject"
                            onChange={handleChange}
                          >
                            <option>{t("faq.selectPlaceholder")}</option>
                            <option value="1">
                              {t("faq.messageFormSelectOption1")}
                            </option>
                            <option value="2">
                              {t("faq.messageFormSelectOption2")}
                            </option>
                            <option value="3">
                              {t("faq.messageFormSelectOption3")}
                            </option>
                          </select>
                          {errors.subject && (
                            <div className="text-danger">{errors.subject}</div>
                          )}
                        </div> */}
                        {/* <div className="mb-4">
                          <textarea
                            className={`form-control ${
                              errors.message && "input-error"
                            }`}
                            id="exampleFormControlTextarea1"
                            rows="3"
                            name="message"
                            value={formData.message}
                            placeholder={t("faq.descPlaceholder")}
                            onChange={handleChange}
                          ></textarea>
                          {errors.message && (
                            <div className="text-danger">{errors.message}</div>
                          )}
                        </div> */}

                        <div className="reCaptchaLogin mb-4">
                          <ReCAPTCHA
                            sitekey={captchaKey}
                            onChange={(value) => handleCaptchaVerify(value)}
                          />
                          {errors.captcha && (
                            <div className="text-danger CaptchaVerify">
                              {errors.captcha}
                            </div>
                          )}
                        </div>
                        <button
                          type="submit"
                          className="btn w-100"
                          onClick={handleClick}
                          style={{
                            backgroundColor: `${
                              secondaryColor &&
                              (hoverColor ? secondaryColor : primaryColor)
                            }`,
                            color: "white",
                          }}
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        >
                          SEND MESSAGE
                          {/* {t("faq.sendMessageButton")} */}
                        </button>
                      </form>
                    </div>
                  </div>
                  {/* <div className="col-md-8">
                    <h1>Contact us</h1>
                    <h2>Need assistance or looking for further information?</h2>
                    <p>
                      Please don't hesitate to contact us and we'll get back to
                      you as soon as possible.
                    </p>
                    <p>Operations - operations@firstnationsjobs.com.au</p>
                    <p>Support - support@firstnationsjobs.com.au</p>
                  </div> */}
                  <div className="col-md-4">
                    <div className="promo-block">
                      <h2>Need assistance?</h2>
                      <p>
                        A member of our staff is ready to help you with any
                        questions you may have.
                      </p>
                      {/* <p>
                        <Link to="" className="btn btn-primary btn-md">
                          Contact us for a fast response
                        </Link>
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </>
        )}
      </div>
    </>
  );
};

export default Contact;
