import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "./NewFooter";
import JobCard from "./JobCard";
import NavBar from "./NavBar";
import axios from "axios";
import BaseApi from "../api/BaseApi";
import ApiKey from "../api/ApiKey";
import HTMLReactParser from "html-react-parser";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  EmailShareButton,
  InstapaperShareButton,
  TelegramShareButton,
} from "react-share";
import {
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  EmailIcon,
  PinterestIcon,
  InstapaperIcon,
  TelegramIcon,
} from "react-share";
import { useTranslation } from "react-i18next";
import Header from "./Header";

const JobDescription = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [jobDescriptiondata, setJobDescriptionData] = useState([]);
  const [relevantJob, setRelevantJob] = useState([]);
  const [loading, setLoading] = useState(false);
  const [skillData, setSkillData] = useState([]);
  const [error, setError] = useState(false);
  const [coverLetterData, setCoverLetterData] = useState([]);
  const [coverLetterSelected, setCoverLetterSelected] = useState();
  let jobSearched = sessionStorage.getItem("jobSearched");

  const tokenKey = Cookies.get("tokenClient");
  const usertype = Cookies.get("usertype");

  const [open, setOpen] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null); // Track the selected payment
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [t, i18n] = useTranslation("global");
  let primaryColor = Cookies.get("primaryColor");
  let secondaryColor = Cookies.get("secondaryColor");
  // let captchaKey = Cookies.get("captchaKey");
  let siteLogo = Cookies.get("siteLogo");

  const [hoverColor, setHoverColor] = useState(false);

  const [hoverSearchColor, setHoverSearchColor] = useState(false);

  const handleSearchMouseEnter = () => {
    setHoverSearchColor(true);
  };

  const handleSearchMouseLeave = () => {
    setHoverSearchColor(false);
  };

  const [hoverUploadCVColor, setHoverUploadCVColor] = useState(false);

  const handleUploadCVMouseEnter = () => {
    setHoverUploadCVColor(true);
  };

  const handleUploadCVMouseLeave = () => {
    setHoverUploadCVColor(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const getData = async () => {
    console.log("getData called");
    try {
      setLoading(true);
      const response = await axios.post(
        BaseApi + `/candidates/apps_jobdetail/${slug}`,
        null,
        {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
          },
        }
      );

      if (response.data.status === 200) {
        setJobDescriptionData(response.data.response);
        const userId = response.data.response.user_id;
        jobViewCount(userId);
        if (jobSearched === "1") {
          jobSearchViewCount(userId);
        }
        sessionStorage.removeItem("jobSearched");
        setRelevantJob(response.data.response.relevantJobList);
        setSkillData(response.data.response.skills_array);
        setCoverLetterData(response.data.response.coverletter);

        // Update Meta Data
        // document.title = response.data.response.meta_tile;

        const metaTitle = document.querySelector('meta[name="title"]');
        console.log(metaTitle.content, "metaTitle");
        let totalMetaTitle =
          response.data.response.title +
          " " +
          "|" +
          " " +
          response.data.response.location +
          " " +
          "|" +
          " " +
          "First Nations Jobs";
        // console.log(totalMetaTitle, "totalMetaTitle");
        if (metaTitle) {
          metaTitle.content = totalMetaTitle;
        }

        const metaDescription = document.querySelector(
          'meta[name="description"]'
        );
        console.log(metaDescription.content, "metaDescription");
        if (metaDescription) {
          metaDescription.content = "";
        }

        const metaKeywords = document.querySelector('meta[name="keywords"]');
        if (metaKeywords) {
          metaKeywords.content = "";
        }
      } else {
        Swal.fire({
          text: response.data.message,
          icon: "error",
          confirmButtonText: t("jobDescription.close"),
        });
      }
    } catch (error) {
      setLoading(false);
      if (error.message === "Network Error") {
        Cookies.remove("tokenClient");
        Cookies.remove("user_type");
        Cookies.remove("fname");
        navigate("/");
        Swal.fire({
          title: t("tokenExpired.tokenExpired"),
          icon: "warning",
          confirmButtonText: t("jobDescription.close"),
        });
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      }
      console.log(
        "Cannot get job description through applied job section at job seeker"
      );
    }
  };

  // const jobViewCount = async (userId) => {
  //   try {
  //     if (userId) {
  //       const response = await axios.post(
  //         BaseApi + `/job/view/${slug}`,
  //         { id: userId },
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             // key: ApiKey,
  //             // token: tokenKey,
  //           },
  //         }
  //       );
  //     }
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };

  const jobViewCount = async (userId) => {
    try {
      // Use the job slug as a key to store the flag in sessionStorage
      const storageKey = `jobViewCounted_${slug}`;
      const storedValue = Cookies.get(storageKey);
      if (!storedValue) {
        const response = await axios.post(
          BaseApi + `/job/view/${slug}`,
          // { id: userId },
          {
            headers: {
              "Content-Type": "application/json",
              // key: ApiKey,
              // token: tokenKey,
            },
          }
        );
        if (response.status === 200) {
          Cookies.set(storageKey, "true");
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const jobSearchViewCount = async (userId) => {
    try {
      const storageKey = `jobSearchCounted_${slug}`;
      const storedValue = Cookies.get(storageKey);
      if (!storedValue) {
        const response = await axios.post(
          BaseApi + `/job/searchView/${slug}`,
          { id: userId },
          {
            headers: {
              "Content-Type": "application/json",
              // key: ApiKey,
              // token: tokenKey,
            },
          }
        );
        if (response.status === 200) {
          Cookies.set(storageKey, "true");
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const applyImportJob = async () => {
    try {
      const response = await axios.post(
        BaseApi + `/job/applyxmljob`,
        { slug: slug },
        {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
          },
        }
      );
    } catch (error) {
      console.log("Couldn't hit applyimportcount job api");
    }
  };
  const getAppliedData = async (id) => {
    try {
      setLoading(true);
      const response = await axios.post(
        BaseApi + `/job/applypop/${id}`,
        null, // Pass null as the request body if not required
        {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
          },
        }
      );
      // setLoading(false);
      // console.log(response);
    } catch (error) {
      setLoading(false);
      console.log("Error at applied button at job description");
    }
  };

  // useEffect(() => {
  //   // Check if tokenKey is not present
  //   if (!tokenKey) {
  //     // Redirect to the home page
  //     navigate("/user/jobseekerlogin");
  //   } else {
  //     // TokenKey is present, fetch data or perform other actions
  //     getAppliedData();
  //     getData();
  //     window.scrollTo(0, 0);
  //   }
  // }, [tokenKey, navigate]);
  useEffect(() => {
    getAppliedData();
    getData();
    window.scrollTo(0, 0);
  }, []);

  const savedJob = async (slug) => {
    try {
      setLoading(true);
      const response = await axios.post(
        BaseApi + `/job/JobSave/${slug}`,
        null, // Pass null as the request body if not required
        {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
          },
        }
      );
      // setLoading(false);
      if (response.data.status === 200) {
        getData();
        Swal.fire({
          title: t("jobDescription.savedJobSuccessTitle"),
          icon: "success",
          confirmButtonText: t("jobDescription.close"),
        });
      } else {
        Swal.fire({
          title: response.data.message,
          icon: "error",
          confirmButtonText: t("jobDescription.close"),
        });
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: t("jobDescription.savedJobFailedTitle"),
        icon: "error",
        confirmButtonText: t("jobDescription.close"),
      });
      console.log("Cannot save job!");
    }
  };

  const handleDeclarationSubmit = async (slug) => {
    if (!isAgreementChecked) {
      setValidationError(t("jobDescription.declarationValidationError"));
      setError(false);
    } else {
      setLoading(true);
      try {
        const response = await axios.post(
          BaseApi + `/job/jobApplyDetail/${slug}`,
          coverLetterSelected,
          {
            headers: {
              "Content-Type": "application/json",
              key: ApiKey,
              token: tokenKey,
            },
          }
        );
        setLoading(false);
        if (response.data.status === 200) {
          getData();
          Swal.fire({
            title: t("jobDescription.jobAppliedSuccessTitle"),
            icon: "success",
            confirmButtonText: t("jobDescription.close"),
          });
        } else {
          getData();
          Swal.fire({
            title: response.data.message,
            icon: "warning",
            confirmButtonText: t("jobDescription.close"),
          });
        }
        // window.location.reload();
      } catch (error) {
        setLoading(false);
        if (error.message === "Network Error") {
          Cookies.remove("tokenClient");
          Cookies.remove("user_type");
          Cookies.remove("fname");
          navigate("/");
          Swal.fire({
            title: t("tokenExpired.tokenExpired"),
            icon: "warning",
            confirmButtonText: t("jobDescription.close"),
          });
          setTimeout(function () {
            window.location.reload();
          }, 3000);
        }
        Swal.fire({
          title: t("jobDescription.jobAppliedFailedTitle"),
          icon: "error",
          confirmButtonText: t("jobDescription.close"),
        });
        console.log("Error on clicking the submit button at apply job modal!");
      }
    }
  };

  const handleClickWithoutLogin = () => {
    navigate("/user/jobseekerlogin");
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: "warning",
      title: t("jobDescription.pleaseLogin"),
    });
  };

  const handleAgreementChange = () => {
    if (validationError) {
      setError(true);
      setValidationError(""); // Clear the validation error when the checkbox is checked
    }
    setIsAgreementChecked(!isAgreementChecked);
    setError(!error);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      <Helmet>
        {/* <title>{`${jobDescriptiondata.meta_tile} | ${jobDescriptiondata.location} | First Nations Jobs`}</title> */}
        <meta name="title" content={`${jobDescriptiondata.meta_tile} | ${jobDescriptiondata.location} | First Nations Jobs`} />
        <meta name="description" content={jobDescriptiondata.meta_description || "hihiih"} />
        <meta name="keywords" content={jobDescriptiondata.meta_keywords || ""} />
      </Helmet>
      {/* <NavBar /> */}
      <Header />
      {loading ? (
        <div className="loader-container"></div>
      ) : (
        <>
          <section className="job-details">
            <div className="container">
              <div className="row">
                <div className="col-sm-8">
                  <div className="titleJobDetails">
                    {jobDescriptiondata.logo !== "" ? (
                      <img
                        src={jobDescriptiondata.logo}
                        alt=""
                        className="img-fluid logoProfile"
                      />
                    ) : (
                      <></>
                      //           <Link to="">
                      //   <img src={siteLogo} alt="" className="logoProfile m-0" />
                      // </Link>
                    )}

                    <h1>
                      {jobDescriptiondata.title
                        ? HTMLReactParser(jobDescriptiondata.title)
                        : ""}
                    </h1>
                    <h2>{jobDescriptiondata.company_name}</h2>
                  </div>
                  <div className="vacany-details">
                    <div className="row">
                      <div className="col-sm-3">Reference #</div>
                      <div className="col-sm-9">
                        {jobDescriptiondata.job_id}
                      </div>
                      {/* <div className="col-sm-3">Closing Date</div>
                      <div className="col-sm-9">
                        {jobDescriptiondata.expire_time}
                      </div> */}
                      <div className="col-sm-3">Salary</div>
                      <div className="col-sm-9">
                        {jobDescriptiondata.salary}
                      </div>
                      {/* <div className="col-sm-3">Start Date</div>
                      <div className="col-sm-9">
                      23-05-2024
                      </div> */}
                      <div className="col-sm-3">Closing Date</div>
                      <div className="col-sm-9">
                        {jobDescriptiondata.expire_time}
                      </div>
                      <div className="col-sm-3">Sector(s)</div>
                      <div className="col-sm-9">
                        {jobDescriptiondata.category}
                      </div>
                      {/* <div className="col-sm-3">Vacancy Type</div>
                      
                        
                      <div className="col-sm-9">{jobDescriptiondata.vacancytype ? jobDescriptiondata.vacancytype : "N/A"}</div> */}

                      <div className="col-sm-3">Work type</div>
                      <div className="col-sm-9">
                        {/* {jobDescriptiondata.job_type
                          ? jobDescriptiondata.job_type
                          : "N/A"} */}
                           {jobDescriptiondata.job_type === 1 && "Full time"}
                            {jobDescriptiondata.job_type === 2 && "Part time"}
                            {jobDescriptiondata.job_type === 3 && "Casual"}
                            {jobDescriptiondata.job_type === 4 && "Seasonal"}
                            {jobDescriptiondata.job_type === 5 && "Fixed"}
                      </div>
                    </div>
                  </div>
                  <div className="jobDescription">
                    {/* <p className="mb-3">About the Role </p> */}

                    <p className="mb-3">
                      {jobDescriptiondata.description
                        ? HTMLReactParser(jobDescriptiondata.description)
                        : ""}
                    </p>
                  </div>

                  <div className="job-options displayNone ">
                    {jobDescriptiondata.apply_url !== null ? (
                      <Link
                        to={jobDescriptiondata.apply_url}
                        className="btn btn-primary btn-lg btn-apply"
                        onClick={applyImportJob}
                      >
                        {t("jobDescription.applyNow")}
                      </Link>
                    ) : null}

                    {jobDescriptiondata.apply_url !== null &&
                      (tokenKey != null ? (
                        jobDescriptiondata.is_saved === 1 ? (
                          <button className="btn btn-primary btn-lg btn-save">
                            <i className="fa fa-download"></i>
                            {t("jobDescription.saved")}
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary btn-lg btn-save"
                            onClick={() => savedJob(slug)}
                          >
                            <i className="fa fa-download"></i>
                            {t("jobDescription.saveJob")}
                          </button>
                        )
                      ) : (
                        <button
                          className="btn btn-primary btn-lg btn-save"
                          onClick={() => handleClickWithoutLogin()}
                        >
                          <i className="fa fa-download"></i>
                          {t("jobDescription.saveJob")}
                        </button>
                      ))}

                    {jobDescriptiondata.apply_url === null &&
                      (tokenKey != null ? (
                        <div className="options-btn">
                          {jobDescriptiondata.apply_url !== null && (
                            <Link
                              to={jobDescriptiondata.apply_url}
                              className="btn btn-primary btn-lg btn-apply"
                              onClick={applyImportJob}
                            >
                              {t("jobDescription.applyNow")}
                            </Link>
                          )}
                          {jobDescriptiondata.apply_url === null &&
                            (jobDescriptiondata.is_applied === 1 ? (
                              <button className="btn btn-primary btn-lg btn-apply">
                                {t("jobDescription.applied")}
                              </button>
                            ) : (
                              <button
                                data-bs-toggle="modal"
                                data-bs-target="#applyModal"
                                className="btn btn-primary btn-lg btn-apply"
                              >
                                {t("jobDescription.applyNow")}
                              </button>
                            ))}

                          {jobDescriptiondata.is_saved === 1 ? (
                            <button className="btn btn-primary btn-lg  btn-save ">
                              <i class="fa fa-download"></i>
                              {t("jobDescription.saved")}
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary btn-lg  btn-save "
                              onClick={() => savedJob(slug)}
                            >
                              <i class="fa fa-download"></i>
                              {t("jobDescription.saveJob")}
                            </button>
                          )}
                        </div>
                      ) : (
                        <div className="btn">
                          <button
                            className="btn btn-primary btn-lg btn-apply"
                            onClick={() => handleClickWithoutLogin()}
                          >
                            {t("jobDescription.applyNow")}
                          </button>

                          <button
                            className="btn btn-primary btn-lg  btn-save "
                            onClick={() => handleClickWithoutLogin()}
                          >
                            <i class="fa fa-download"></i>
                            {t("jobDescription.saveJob")}
                          </button>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="job-options">
                    {jobDescriptiondata.apply_url !== null ? (
                      <Link
                        to={jobDescriptiondata.apply_url}
                        className="btn btn-primary btn-lg btn-apply"
                        onClick={applyImportJob}
                      >
                        {t("jobDescription.applyNow")}
                      </Link>
                    ) : null}

                    {jobDescriptiondata.apply_url !== null &&
                      (tokenKey != null ? (
                        jobDescriptiondata.is_saved === 1 ? (
                          <button className="btn btn-primary btn-lg btn-save">
                            <i className="fa fa-download"></i>
                            {t("jobDescription.saved")}
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary btn-lg btn-save"
                            onClick={() => savedJob(slug)}
                          >
                            <i className="fa fa-download"></i>
                            {t("jobDescription.saveJob")}
                          </button>
                        )
                      ) : (
                        <button
                          className="btn btn-primary btn-lg btn-save"
                          onClick={() => handleClickWithoutLogin()}
                        >
                          <i className="fa fa-download"></i>
                          {t("jobDescription.saveJob")}
                        </button>
                      ))}

                    {jobDescriptiondata.apply_url === null &&
                      (tokenKey != null ? (
                        <div className="options-btn">
                          {jobDescriptiondata.apply_url !== null && (
                            <Link
                              to={jobDescriptiondata.apply_url}
                              className="btn btn-primary btn-lg btn-apply"
                              onClick={applyImportJob}
                            >
                              {t("jobDescription.applyNow")}
                            </Link>
                          )}
                          {jobDescriptiondata.apply_url === null &&
                            (jobDescriptiondata.is_applied === 1 ? (
                              <button className="btn btn-primary btn-lg btn-apply">
                                {t("jobDescription.applied")}
                              </button>
                            ) : (
                              <button
                                data-bs-toggle="modal"
                                data-bs-target="#applyModal"
                                className="btn btn-primary btn-lg btn-apply"
                              >
                                {t("jobDescription.applyNow")}
                              </button>
                            ))}

                          {jobDescriptiondata.is_saved === 1 ? (
                            <button className="btn btn-primary btn-lg  btn-save ">
                              <i class="fa fa-download"></i>
                              {t("jobDescription.saved")}
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary btn-lg  btn-save "
                              onClick={() => savedJob(slug)}
                            >
                              <i class="fa fa-download"></i>
                              {t("jobDescription.saveJob")}
                            </button>
                          )}
                        </div>
                      ) : (
                        <div className="btn">
                          <button
                            className="btn btn-primary btn-lg btn-apply"
                            onClick={() => handleClickWithoutLogin()}
                          >
                            {t("jobDescription.applyNow")}
                          </button>

                          <button
                            className="btn btn-primary btn-lg  btn-save "
                            onClick={() => handleClickWithoutLogin()}
                          >
                            <i class="fa fa-download"></i>
                            {t("jobDescription.saveJob")}
                          </button>
                        </div>
                      ))}

                    <div className="apply-links">
                      <p className="d-flex align-items-center gap-1">
                        <i class="fa fa-plus-circle"></i>
                        <Link
                          to={`/companyrelatedjobs/${jobDescriptiondata.company_name}`}
                        >
                          MORE JOBS BY THIS ADVERTISER
                        </Link>
                      </p>
                    </div>
                  </div>

                  <div className="promo-block">
                    <h2>Find your next job faster than ever!</h2>
                    <ul>
                      <li>
                        <img
                          src="/Images/checked_icon.png"
                          alt="icon"
                          className="img-fluid"
                        />
                        FREE online registration
                      </li>
                      <li>
                        <img
                          src="/Images/checked_icon.png"
                          alt="icon"
                          className="img-fluid"
                        />
                        Daily job alerts
                      </li>
                      <li>
                        <img
                          src="/Images/checked_icon.png"
                          alt="icon"
                          className="img-fluid"
                        />
                        Online profile &amp; CV (searchable by clients)
                      </li>
                      <li>
                        <img
                          src="/Images/checked_icon.png"
                          alt="icon"
                          className="img-fluid"
                        />
                        Large client &amp; agency database
                      </li>
                    </ul>
                    <p>
                      {!tokenKey && (
                        <Link
                          to="/user/register/jobseeker"
                          className="btn btn-primary "
                        >
                          Register now
                        </Link>
                      )}

                      {/* <Link
                        to="/user/register/jobseeker"
                        className="btn btn-primary "
                      >
                        Register now
                      </Link> */}
                    </p>
                  </div>

                  <div className="promo-block">
                    <h2>Maximize your chances!</h2>
                    <p className="text-customize">
                      Ensure that your online CV is up to date as employers are
                      always searching our online CV database of candidates.
                    </p>
                    <p>
                      {tokenKey != null ? (
                        <Link
                          to="/candidates/myaccount"
                          className="btn btn-primary "
                        >
                          Modify your profile
                        </Link>
                      ) : (
                        <Link
                          to="/user/jobseekerlogin"
                          className="btn btn-primary "
                        >
                          Modify your profile
                        </Link>
                      )}
                      {/* <Link
                        to="/candidates/myaccount"
                        className="btn btn-primary "
                      >
                        Modify your profile
                      </Link> */}
                    </p>
                  </div>

                  <div className="promo-block">
                    <h2>Need assistance?</h2>
                    <p className="text-customize">
                      A member of our staff is ready to help you with any
                      questions you may have.
                    </p>
                    <p>
                      <Link to="/contact" className="btn btn-primary ">
                        Contact us for a fast response
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Reply Modal  */}
          <div
            className="modal fade  membershipModal"
            id="applyModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1
                    className="text-center modal-title fs-5"
                    id="exampleModalLabel"
                  >
                    {t("jobDescription.jobApplicationConfirmation")}
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body text-center">
                  <h5 className="m-2">{t("jobDescription.declaration")}:</h5>
                  <p>{t("jobDescription.declarationTxt")}</p>
                  <div className="m-2">
                    <input
                      type="checkbox"
                      name="agreement"
                      checked={isAgreementChecked}
                      onChange={handleAgreementChange}
                      required
                    />
                    <span className="RedStar">*</span>{" "}
                    {t("jobDescription.declarationAgreed")}
                  </div>
                  {validationError && (
                    <p style={{ color: "red" }}>{validationError}</p>
                  )}
                  <h5 className="m-2">
                    {" "}
                    {t("jobDescription.selectCoverLetter")}
                  </h5>
                  {coverLetterData &&
                    coverLetterData.map((i) => {
                      return (
                        <>
                          <input
                            type="radio"
                            id={i.id}
                            name="coverletter"
                            value={i.id}
                            onChange={(e) =>
                              setCoverLetterSelected(e.target.value)
                            }
                          />{" "}
                          {i.title}
                        </>
                      );
                    })}
                  <hr />
                  <div className="d-flex justify-content-evenly">
                    <button
                      type="button"
                      className="btn btn-primary button1"
                      style={{
                        backgroundColor: hoverSearchColor
                          ? secondaryColor
                          : primaryColor,
                        border: hoverSearchColor
                          ? secondaryColor
                          : primaryColor,
                      }}
                      onMouseEnter={handleSearchMouseEnter}
                      onMouseLeave={handleSearchMouseLeave}
                      data-bs-dismiss={error && `modal`}
                      aria-label={error && `close`}
                      onClick={() => handleDeclarationSubmit(slug)}
                    >
                      {t("jobDescription.submitButton")}
                    </button>
                    <Link
                      to="/candidates/editprofile"
                      type="button"
                      className="btn btn-primary button2"
                      style={{
                        color: hoverUploadCVColor
                          ? primaryColor
                          : secondaryColor,
                        backgroundColor: "white",
                        border: hoverUploadCVColor
                          ? `2px solid ${primaryColor}`
                          : `2px solid ${secondaryColor}`,
                      }}
                      onMouseEnter={handleUploadCVMouseEnter}
                      onMouseLeave={handleUploadCVMouseLeave}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => navigate("/candidates/editprofile")}
                    >
                      {t("jobDescription.addCoverLetter")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </>
      )}
    </>
  );
};

export default JobDescription;
