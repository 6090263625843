import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../element/NewFooter";
import Header from "../element/Header";
import Cookies from "js-cookie";

const NewLgbtiJobs = () => {
  const [loading, setLoading] = useState(false);

  const tokenKey = Cookies.get("tokenClient");
  let userType = Cookies.get("user_type");
  return (
    <>
      <Header />
      {loading ? (
        <div className="loader-container"></div>
      ) : (
        <>
          <div className="content-general m-0 accountRegistry">
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <h1>New to First Nations Jobs?</h1>
                  <h2>
                    We're dedicated to assisting First Nations graduates and
                    jobseekers connect with organisations actively engaged in
                    Aboriginal and Torres Strait Islander inclusion initiatives.
                  </h2>
                  <hr />
                  <ul className="icon-list">
                    <li>
                      {/* <i class="fa fa-check-square-o"></i>{" "} */}
                      <i class="fa fa-check-square-o" aria-hidden="true"></i>
                      <strong>Pre-Screened Businesses</strong>
                      <br />
                      All companies advertising on First Nations Jobs are
                      obliged to pass our Cultural Compliance Assessment to
                      ensure they have a truly inclusive culture with relevant
                      policies in place to provide a completely safe workplace.
                      We take this seriously and conduct in-depth discussions
                      with all our advertisers so they can walk us through their
                      processes and we even provide in-house training.
                    </li>

                    <li>
                      <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                      {/* <i>
                        <img src="/Images/like2.png" alt="" />
                        </i> {""} */}
                      <strong>AN INDEPENDENT VOICE</strong>
                      <br />
                      First Nations Jobs guarantees no bias or spin. We ensure
                      that every company advertising on our site provides a safe
                      work place for everyone, and that isn't just about policy;
                      it's about ensuring a truly inclusive workplace culture.
                    </li>
                  </ul>

                  <hr />

                  <h3>
                    <Link
                      to="/user/register/jobseeker"
                      style={{ textDecoration: "none" }}
                    >
                      Create a FREE account
                    </Link>{" "}
                    today and gain access to the following features:
                  </h3>

                  <ul className="icon-list">
                    <li>
                      <i class="fa fa-save"></i>{" "}
                      {/* <i class="fa material-icons">save</i> */}
                      <strong>Save jobs &amp; applications</strong>
                      <br />
                      Avoid forgetting jobs by saving them so you can apply or
                      review them at a time convenient to you.
                    </li>
                    <li>
                      {/* <i class="fa fa-envelope"></i>{" "} */}
                      <i class="fa fa-envelope" aria-hidden="true"></i>
                      {""}
                      <strong>Create customised email alerts</strong>
                      <br />
                      Simply select the criteria relevant to you and we'll email
                      you with the latest jobs each day!
                    </li>
                    <li>
                      {/* <i class="fa fa-user-plus"></i>{" "} */}
                      <i class="fa fa-user-plus" aria-hidden="true"></i>
                      <strong>Create an online profile</strong>
                      <br />
                      Maximize your chances of finding work by uploading your
                      online CV and creating a profile, which is searchable by
                      companies and recruiters. You can even remain anonymous
                      and allow access upon request.
                    </li>
                  </ul>

                  {!tokenKey && (
                    <>
                      <p className="promo-block bg-white border-bottom-0">
                        <Link
                          to="/user/register/jobseeker"
                          className="btn btn-primary "
                        >
                          Register Online
                        </Link>
                      </p>
                    </>
                  )}
                </div>
                <div className="col-md-4 ">
                  <div className="promo-block">
                    <h2>Find your next job faster than ever!</h2>
                    <ul>
                      <li>
                        <img
                          src="/Images/checked_icon.png"
                          alt="icon"
                          className="img-fluid"
                        />
                        FREE online registration
                      </li>
                      <li>
                        <img
                          src="/Images/checked_icon.png"
                          alt="icon"
                          className="img-fluid"
                        />
                        Daily job alerts
                      </li>
                      <li>
                        <img
                          src="/Images/checked_icon.png"
                          alt="icon"
                          className="img-fluid"
                        />
                        Online profile & CV (searchable by clients)
                      </li>
                      <li>
                        <img
                          src="/Images/checked_icon.png"
                          alt="icon"
                          className="img-fluid"
                        />
                        Large client & agency database
                      </li>
                      {!tokenKey && (
                        <>
                          <p>
                            <Link
                              to="/user/register/jobseeker"
                              className="btn btn-primary mt-2"
                            >
                              Register Now
                            </Link>
                          </p>
                        </>
                      )}
                    </ul>
                  </div>

                  <div className="promo-block">
                    <h2>Need assistance?</h2>
                    <p className="text-customize">
                      A member of our staff is ready to help you with any
                      questions you may have.
                    </p>
                    <p>
                      <Link to="/contact" className="btn btn-primary">
                        Contact us for a fast response
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </>
      )}
    </>
  );
};

export default NewLgbtiJobs;
