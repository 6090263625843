// const BaseApi = "https://new.lgbtjobs.com.au/job-board-script/api"
// const BaseApi = "https://firstnationsjobs.logicspice.com/job-board-script/api"
// const BaseApi = "https://new.firstnationsjobs.com.au/api"
const BaseApi = "https://api.firstnationsjobs.com.au/api"

// const BaseApi = "https://firstnationsjobs.logicspice.com/job-board-script/api"



export default BaseApi

